
body{
    background: "white"
}
.label-headline{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 2px;
}

.label-info{
    background-color: #337ab7;
    padding:3px 8px;
    color:white;
    border-radius: 5px;
}

.custom-row{
    display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}
.dropdown-item a{
    color:black !important;
    text-decoration: none;
}
.dropdown-item:nth-child(even){
    background-color:aliceblue
}

.btn-info {
    color: #000;
    background-color: white;
    border-color: grey;
    margin:3px;
    padding:10px;
}


.bg-light {
    background-color: white !important;
}

